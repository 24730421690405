<template>
  <div
    class="cta-button-container">
    <a
      href="/files/Nadia-Rodriguez-Resume.pdf"
      class="cta-button"
      download="Nadia-Rodriguez-Resume.pdf">
      download pdf
    </a>
  </div>
  <main class="resume" ref="resume">
    <header>
      <div class="header-container">
        <h1 class="header-title">{{ resume.name }}</h1>
        <p class="header-subtitle">{{ resume.occupation }}</p>
      </div>
    </header>
    <aside>
      <div class="sidebar-blocks">
        <div
          v-for="(block, idx) in resume.sidebar_blocks"
          :key="idx"
          class="sidebar-block">
          <span class="sidebar-block--title">
            {{ block.label }}
          </span>
          <ul
            class="sidebar-block--items"
            v-if="block.items.length > 0">
            <li
              v-for="(item, item_idx) in block.items"
              :key="item_idx"
              class="sidebar-block--item">
              <label
                class="sidebar-block--item__label"
                v-if="item.label">
                {{ item.label }}
              </label>
              <a
                :href="item.link"
                target="_blank"
                rel="noopener noreferrer"
                v-if="item.is_link"
                class="sidebar-block--item__text">
                {{ item.text }}
              </a>
              <p
                class="sidebar-block--item__text"
                v-else>{{ item.text }}</p>
            </li>
          </ul>
        </div>
      </div>
    </aside>
    <section>
      <div class="main-blocks">
        <div
          v-for="(block, idx) in resume.main_blocks"
          :key="idx"
          class="main-block">
          <h2 class="main-block--title">
            {{ block.label }}
          </h2>
          <p
            v-if="block.description">
            {{ block.description }}
          </p>
          <div
            v-if="block.items.length > 0"
            class="main-block--items">
            <div
              v-for="(item, item_idx) in block.items"
              :key="item_idx"
              class="main-block--item">
              <h3 class="main-block--item__title">
                {{ item.title }}
                <span class="main-block--item__location">
                  {{ item.location }}
                </span>
              </h3>
              <p class="main-block--item__dates">
                {{ item.dates }}
              </p>
              <ul
                v-if="item.bullet_points.length > 0"
                class="main-block--item__bullets">
                <li
                  v-for="(point, point_idx) in item.bullet_points"
                  :key="point_idx">
                  {{ point }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import resume from '@/utils/resume.json'
import html2pdf from 'html2pdf.js'

export default {
  name: 'Resume',
  data () {
    return {
      resume: resume
    }
  },
  created () {
    this.scrollIntoView()
  },
  methods: {
    scrollIntoView () {
      if (document.body.scrollTop > 0) {
        window.scroll({ top: 0, behavior: 'smooth' })
      }
    },
    generatePdf () {
      html2pdf(this.$refs.resume, {
        filename: 'Nadia-Rodriguez-Resume.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 1.5, width: 794, height: 1123, windowWidth: 800, dpi: 192, letterRendering: true },
        jsPDF: { orientation: 'portrait', unit: 'px', hotfixes: ['px_scaling'] }
      }).save()
    }
  }
}
</script>

<style lang="scss">
.cta-button {
  border: none;
  font-size: 16px;
  line-height: 1;
  font-family: "Fira Sans", monospace;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 99;
  background:  #eee;
  text-decoration: none;
  color: #000;
  &-container {
    max-width: 1120px;
    margin: 0 auto;
    position: relative;
  }
}
main.resume {
  display: flex;
  color: #000;
  font-family: "Fira Code", monospace;
  flex-wrap: wrap;
  position: relative;
  max-width: 1120px;
  margin: 0 auto;
  header {
    height: 270px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header {
    &-container {
      border: 2px solid #000;
      padding: 50px;
      background: #fff;
      text-align: center;
    }
    &-title {
      font-family: owners-xwide, sans-serif;
      font-weight: 700;
      letter-spacing: .1em;
      text-transform: uppercase;
      font-size: 40px;
      line-height: 1;
      text-align: center;
    }
    &-subtitle {
      text-transform: uppercase;
      letter-spacing: .05em;
      font-size: 18px;
      margin-top: 10px;
      text-align: center;
    }
  }
  aside {
    width: 350px;
    text-align: left;
    padding: 290px 2rem 0;
    background: #f3f3f3;
  }
  .sidebar {
    &-block {
      &s {
        width: calc(100% - 50px);
        margin: 0 auto;
      }
      &--title {
        display: block;
        font-family: owners-xwide, sans-serif;
        font-weight: 700;
        letter-spacing: .1em;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 1;
        border-bottom: 2px solid #000;
        padding-bottom: 5px;
      }
      &--item {
        margin-bottom: 8px;
        &s {
          margin: 20px 0 30px;
        }
        &__dates {
          color: #555;
        }
        &__label {
          font-weight: 700;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: .05em;
        }
        &__text {
          font-size: 16px;
          color: #000;
        }
      }
    }
  }
  section {
    width: calc(100% - 350px);
    text-align: left;
    padding: 290px 2rem 0;
  }
  .main {
    &-block {
      margin-bottom: 40px;
      &s {
        width: calc(100% - 50px);
        margin: 0 auto;
      }
      &--title {
        font-family: owners-xwide, sans-serif;
        font-weight: 700;
        letter-spacing: .1em;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 1;
        border-bottom: 2px solid #000;
        padding-bottom: 5px;
        margin-bottom: 20px;
      }
      &--item {
        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          font-weight: 700;
          font-size: 18px;
        }
        &__location {
          font-size: 14px;
          font-weight: 400;
        }
        &__bullets {
          margin: 15px 0 30px;
          li {
            margin-bottom: 10px;
            position: relative;
            padding-left: 20px;
            &:before {
              content: '•';
              font-size: 18px;
              line-height: 0;
              position: absolute;
              top: 10px;
              left: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  main.resume {
    .sidebar {
      &-block {
        &s {
          width: 100%;
        }
        &--title {
          font-size: 14px;
        }
        &--item {
          margin-bottom: 5px;
          &s {
            margin: 10px 0 25px;
          }
          &__text {
            font-size: 12px;
          }
          &__label {
            font-size: 12px;
          }
        }
      }
    }
    .main {
      &-block {
        margin-bottom: 30px;
        font-size: 13px;
        &s {
          width: 100%;
        }
        &--title {
          font-size: 14px;
          margin-bottom: 12px;
        }
        &--item {
          &__title {
            font-size: 14px;
          }
          &__bullets {
            margin: 10px 0 15px;
            li {
              margin-bottom: 8px;
              padding-left: 15px;
            }
          }
        }
      }
    }
    header {
      height: 180px;
    }
    .header {
      &-title {
        font-size: 30px;
      }
      &-subtitle {
        font-size: 14px;
        margin-top: 5px;
      }
      &-container {
        padding: 40px 30px 35px;
      }
    }
    aside {
      width: 230px;
      padding: 190px 1.5rem 0;
    }
    section {
      width: calc(100% - 230px);
      padding: 190px 1.5rem 0;
    }
  }
}

@media (max-width: 793px) {
  main.resume {
    flex-wrap: wrap-reverse;
    header {
      height: auto;
      position: relative;
      order: 1;
    }
    .header {
      &-container {
        padding: 100px 30px 25px;
        border: none;
      }
    }
    section {
      padding: 30px 1.5rem 0;
      width: 100%;
    }
    aside {
      padding: 40px 1.5rem 0;
      width: 100%;
    }
    .main {
      &-block {
        font-size: 16px;
        margin-bottom: 40px;
        &s {
          max-width: 550px;
        }
        &--title {
          font-size: 16px;
        }
        &--item {
          &__title {
            font-size: 16px;
            flex-direction: column;
            align-items: flex-start;
          }
          &__bullets {
            margin: 10px 0 25px;
          }
          &__location {
            margin: 5px 0;
          }
          &__dates {
            font-size: 14px;
          }
        }
      }
    }
    .sidebar {
      &-block {
        &s {
          max-width: 550px;
        }
        &--title {
          font-size: 16px;
        }
        &--item {
          margin-bottom: 8px;
          &__text {
            font-size: 16px;
          }
          &__label {
            font-size: 14px;
          }
        }
      }
    }
  }
  .cta-button {
    font-size: 14px;
    top: 0;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 801px) and (max-width: 1100px) {
  main.resume {
    .main {
      &-blocks {
        width: 100%;
      }
    }
    .sidebar {
      &-blocks {
        width: 100%;
      }
    }
    aside {
      width: 310px;
    }
    section {
      width: calc(100% - 310px);
    }
  }
}
</style>
