<template>
  <h1
    class="animated-words"
    ref="words"
    :class="{
      'on-body-load': onBodyLoad
    }"
    v-if="isHeading">
    <slot></slot>
  </h1>
  <span
    class="animated-words"
    ref="words"
    :class="{
      'on-body-load': onBodyLoad
    }"
    v-else>
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'AnimateWords',
  props: {
    onBodyLoad: {
      type: Boolean,
      default: false
    },
    isHeading: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.animateWords()
  },
  methods: {
    animateWords () {
      // console.log(this.$refs.words)
      const el = this.$refs.words
      const words = el.innerText.split(' ')
      const wordCount = words.length

      el.innerHTML = ' '

      for (let i = 0; i < wordCount; i++) {
        el.innerHTML += '<span>' + words[i] + '</span>'
        if (i < wordCount - 1) {
          el.innerHTML += ' '
        }
      }

      Array.prototype.forEach.call(el.children, (word, i) => {
        if (word.innerText !== ' ') {
          word.setAttribute('style', `--word-index: ${i}`)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.animated-words {
  span {
    display: inline-block;
    transition: transform 2s cubic-bezier(0, 0.5, 0.5, 1), opacity 2s linear, -webkit-transform 2s cubic-bezier(0, 0.5, 0.5, 1);
    transition-delay: calc(0.08s * var(--word-index));
    opacity: 0;
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
  }
}
body.ready {
  .animated-words.on-body-load {
    span {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}
.going-in {
  .animated-words {
    span {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}
</style>
