import * as Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Resume from '../views/Resume.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Nadia Rodriguez | Portfolio'
    }
  },
  {
    path: '/resume',
    name: 'resume',
    component: Resume,
    meta: {
      title: 'Nadia Rodriguez | Resume'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = to.meta.title
    }
  })
})

export default router
