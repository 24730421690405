<template>
  <div
    class="sec-two-blocks--item"
    :class="{
      'is-inverse': isInverse
    }"
    :style="{
      '--block-index': index
    }">
    <div class="sec-two-blocks--item__wrapper">
      <div class="sec-two-blocks--item__media">
        <img :src="mediaUrl" :alt="mediaAlt">
      </div>
      <div class="sec-two-blocks--item__description">
        <h3 class="sec-two-blocks--item__title">
          {{ title }}
        </h3>
        <p class="sec-two-blocks--item__excerpt" v-html="excerpt"></p>
      </div>
      <a
        :href="ctaLink"
        class="sec-two-blocks--item__cta animated-colors"
        target="_blank"
        rel="noopener">
        <span>{{ ctaText }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecTwoBlocksItem',
  props: {
    mediaUrl: String,
    mediaAlt: String,
    title: String,
    excerpt: String,
    ctaLink: String,
    ctaText: String,
    isInverse: {
      type: Boolean,
      default: false
    },
    index: Number
  }
}
</script>

<style lang="scss">

</style>
