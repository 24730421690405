<template>
  <Hero />
  <SecTwo :onWaypoint="onWaypoint" />
  <SecThree :onWaypoint="onWaypoint" />
  <Footer :onWaypoint="onWaypoint" />
</template>

<script>
import Hero from '@/components/hero/Hero.vue'
import SecTwo from '@/components/sec-two/SecTwo.vue'
import SecThree from '@/components/sec-three/SecThree.vue'
import Footer from '@/components/footer/Footer.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    SecTwo,
    SecThree,
    Footer
  },
  setup () {
    const onWaypoint = (waypointState) => {
      if (waypointState.going === 'IN') {
        waypointState.el.classList.add('visible')
      }
    }

    return { onWaypoint }
  },
  mounted () {
    document.addEventListener('DOMContentLoaded', () => {
      document.querySelector('body').classList.add('ready')
    })
  }
}
</script>

<style lang="scss">

</style>
