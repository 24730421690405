<template>
  <section class="hero">
    <div class="hero-bg"></div>
    <div class="container">
      <animate-words
        :on-body-load="true"
        :is-heading="true">
        Web developer based in San Diego, CA
      </animate-words>
    </div>
  </section>
</template>

<script>
import AnimateWords from '@/components/AnimateWords.vue'

export default {
  name: 'Hero',
  components: {
    AnimateWords
  }
}
</script>

<style lang="scss">
.hero {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  h1 {
    text-align: center;
    font-size: 90px;
    line-height: 100px;
    color: #fff;
    font-family: owners-xwide, sans-serif;
    font-weight: 700;
    max-width: 1220px;
    letter-spacing: .05em;
    margin: 0 auto;
    text-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    max-width: 960px;
     span {
       &:nth-child(3) {
        position: relative;
        padding-left: 150px;
        &:before {
          content: '';
          height: 8px;
          width: 130px;
          display: inline-block;
          background: linear-gradient(to right, transparent, #fff, transparent);
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%) scaleX(0);
          transition: transform 1s cubic-bezier(0, 0.5, 0.5, 1);
          transition-delay: 1s;
        }
      }
    }
  }
  &-bg {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: url('../../assets/img/gradient.png');
    background-size: cover;
    &:after {
      content: '';
      position: fixed;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('../../assets/img/noise.png');
      mix-blend-mode: overlay;
      opacity: .35;
    }
  }
}

body.ready {
  .hero {
    h1 {
      span {
        &:nth-child(3) {
          &:before {
            transform: translateY(-50%) scaleX(1);
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .hero {
    padding: 0 1.5rem;
    h1 {
      font-size: 6vw;
      line-height: 1.3;
      max-width: calc(100% - 90px);
      margin: 0 auto;
      span {
        &:nth-child(3) {
          padding-left: 70px;
          &:before {
            width: 60px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1170px) {
  .hero {
    padding: 0 1.5rem;
    h1 {
      margin: 0 auto;
      font-size: 45px;
      line-height: 55px;
      max-width: unset;
    }
  }
}

@media (min-width: 1171px) and (max-width: 1380px) {
  .hero {
    h1 {
      font-size: 60px;
      line-height: 70px;
      max-width: 920px;
    }
  }
}
</style>
