<template>
  <section class="footer">
    <div class="footer-top">
      <div class="container">
        <waypoint
          :tag="'h2'"
          class="section-title"
          @change="onWaypoint">
          <a href="mailto:contact@nadiarodriguez.dev">
            Available for work! <br>
            <animate-words>contact@nadiarodriguez.dev</animate-words>
          </a>
        </waypoint>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="flex">
          <a
            href="https://www.linkedin.com/in/nadiarodriguez330/"
            target="_blank"
            rel="noopener">
            <img src="@/assets/img/linkedin.svg">
          </a>
          <a
            href="https://github.com/nadsr2"
            target="_blank"
            rel="noopener">
            <img src="@/assets/img/github.svg">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import AnimateWords from '@/components/AnimateWords'

export default {
  name: 'Footer',
  components: {
    Waypoint,
    AnimateWords
  },
  props: {
    onWaypoint: {
      type: Function
    }
  }
}
</script>

<style lang="scss">
.footer {
  &-top {
    padding: 8rem 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
  }
  &-bottom {
    padding: 2.25rem 2rem;
    .flex {
      max-width: 80px;
      margin: 0 auto;
      img {
        display: block;
        width: 25px;
        height: 25px;
        margin: 0 10px;
      }
    }
  }
 .section-title {
    font-size: 30px;
    margin-bottom: 0;
    a {
      display: block;
      color: inherit;
      text-decoration: none;
    }
    .animated-colors {
      height: 10px;
      margin: -15px 0 0;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    .section-title {
      font-size: 16px;
      max-width: 100%;
      line-height: 1.5;
    }
    &-top {
      padding: 6rem 1.5rem;
    }
    &-bottom {
      padding: 1.25rem 1.5rem;
      .flex {
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1170px) {
  .footer {
    .section-title {
      max-width: 100%;
      font-size: 25px;
      line-height: 1.25;
      .animated-colors {
        height: 10px;
        margin: -11px 0 0;
      }
    }
    .flex {
      img {
        width: 25px;
        height: 25px;
      }
    }
    &-top {
      padding: 6rem 1.5rem;
    }
    &-bottom {
      padding: 1.25rem 1.5rem;
    }
  }
}

@media (min-width: 1171px) and (max-width: 1380px) {
  .footer {
    &-top {
      padding: 7rem 2rem;
    }
    .section-title {
      font-size: 28px;
    }
  }
}
</style>
