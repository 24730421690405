<template>
  <section id="sec-three">
    <div class="container">
      <waypoint
        :tag="'h2'"
        class="section-title"
        @change="onWaypoint">
        <animate-words>
          About Me
        </animate-words>
      </waypoint>
      <div class="content">
        <p>Hi! My name is Nadia Rodriguez. I am a self-taught web developer from San Diego, CA.</p>
        <p>I have 3+ years of professional and freelance experience building websites and web apps with an agency as well as with freelance clients.</p>
        <p>I have worked extensively with the following technologies in these past 3 years: Vue.js, Wordpress, Flask/Django.</p>
        <p>I am available for work!</p>
        <router-link
          class="button"
          :to="{ name: 'resume' }"
          target="_blank"
          rel="noopener noreferrer">
          <span>View Resume</span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import AnimateWords from '@/components/AnimateWords'

export default {
  name: 'SecThree',
  components: {
    Waypoint,
    AnimateWords
  },
  props: {
    onWaypoint: {
      type: Function
    }
  }
}
</script>

<style lang="scss">
#sec-three {
  padding: 8rem 2rem;
  .container {
    max-width: 750px;
  }
  .content {
    .button {
      margin-top: calc(2.5rem - 25px);
    }
  }
}

@media (max-width: 767px) {
  #sec-three {
    padding: 6rem 1.5rem;
    .container {
      max-width: 570px;
    }
    .content {
      margin-top: 3rem;
    }
  }
}

@media (min-width: 768px) and (max-width: 1170px) {
  #sec-three {
    padding: 6rem 1.5rem;
    .container {
      max-width: 570px;
    }
    .content {
      margin-top: 3rem;
    }
  }
}

@media (min-width: 1171px) and (max-width: 1380px) {
  #sec-three {
    padding: 7rem 2rem;
  }
}
</style>
