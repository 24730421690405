const mixins = {
  methods: {
  }
}

export default {
  install (Vue, options) {
    Vue.mixins(mixins)
  }
}
