<template>
  <waypoint
    :tag="'section'"
    id="sec-two"
    @change="onWaypoint"
    :options="{
      root: document,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0.25, 0.75]
    }">
    <div class="container">
      <h2 class="section-title">
        <animate-words>
          Featured Work
        </animate-words>
      </h2>
      <SecTwoBlocks />
    </div>
  </waypoint>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import SecTwoBlocks from '@/components/sec-two/SecTwoBlocks.vue'
import AnimateWords from '@/components/AnimateWords'

export default {
  name: 'SecTwo',
  components: {
    SecTwoBlocks,
    AnimateWords,
    Waypoint
  },
  props: {
    onWaypoint: {
      type: Function
    }
  }
}
</script>

<style lang="scss">
#sec-two {
  padding: 8rem 2rem;
  border-top: 1px solid rgba(255, 255, 255, .2);
}

@media (max-width: 767px) {
  #sec-two {
    padding: 6rem 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1170px) {
  #sec-two {
    padding: 6rem 1.5rem;
  }
}

@media (min-width: 1171px) and (max-width: 1380px) {
  #sec-two {
    padding: 7rem 2rem;
  }
}
</style>
