<template>
  <div
    class="sec-two-blocks">
    <SecTwoBlocksItem
      v-for="(block, idx) in secTwoBlocks"
      :key="idx"
      :isInverse="idx % 2 !== 0"
      :mediaUrl="block.mediaUrl"
      :mediaAlt="block.mediaAlt"
      :title="block.title"
      :excerpt="block.excerpt"
      :ctaLink="block.ctaLink"
      :ctaText="block.ctaText"
      :index="idx" />
  </div>
</template>

<script>
import SecTwoBlocksItem from '@/components/sec-two/SecTwoBlocksItem'

export default {
  name: 'SecTwoBlocks',
  components: {
    SecTwoBlocksItem
  },
  data () {
    return {
      secTwoBlocks: [
        {
          mediaUrl: require('@/assets/img/lileswhite.svg'),
          mediaAlt: 'Liles White PLLC',
          title: 'Liles White',
          excerpt: `
            Liles White PLLC are trial attorneys based in Texas and New Mexico.
            This wordpress theme was built by me under the iLawyer Marketing team.
            <span>Tools used: HTML, CSS, jQuery & Javascript.</span>
          `,
          ctaLink: 'https://lileswhite.com',
          ctaText: 'View Website'
        },
        {
          mediaUrl: require('@/assets/img/joblaw.svg'),
          mediaAlt: 'Sessions & Kimball',
          title: 'Sessions & Kimball',
          excerpt: `
            Sessions & Kimball are employee rights attorneys based in Orange County, CA.
            This wordpress theme was built by me under the iLawyer Marketing team.
            <span>Tools used: HTML, CSS, jQuery & Javascript.</span>
          `,
          ctaLink: 'https://job-law.com',
          ctaText: 'View Website'
        },
        {
          mediaUrl: require('@/assets/img/ltke.svg'),
          mediaAlt: 'Lynch, Traub, Keefe & Errante',
          title: 'LTKE Law',
          excerpt: `
            LTKE Law are trial advocacy and litigation
            attorneys based in Connecticut.
            This wordpress theme was built by me under the iLawyer Marketing team.
            <span>Tools used: HTML, CSS, jQuery & Javascript.</span>
          `,
          ctaLink: 'https://ltke.com',
          ctaText: 'View Website'
        },
        {
          mediaUrl: require('@/assets/img/naglefirm.svg'),
          mediaAlt: 'Nagle & Associates',
          title: 'Nagle & Associates',
          excerpt: `
            Nagle & Associates are personal injury attorneys in North Carolina.
            This wordpress theme was built by me under the iLawyer Marketing team.
            <span>Tools used: HTML, CSS, jQuery & Javascript.</span>
          `,
          ctaLink: 'https://naglefirm.com',
          ctaText: 'View Website'
        },
        {
          mediaUrl: require('@/assets/img/wilklawfirm.svg'),
          mediaAlt: 'Wilk Law',
          title: 'Wilk Law',
          excerpt: `
            Wilk Law is a personal injury law firm in Pennsylvania.
            This wordpress theme was built by me under the iLawyer Marketing team.
            <span>Tools used: HTML, CSS, jQuery & Javascript.</span>
          `,
          ctaLink: 'https://wilklawfirm.com',
          ctaText: 'View Website'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
#sec-two.going-in {
  .sec-two-blocks--item {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.sec-two-blocks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 6rem auto 0;
  position: relative;
  z-index: 1;
  &--item {
    width: calc(100% / 3);
    text-align: center;
    transition: transform 2s cubic-bezier(0, 0.5, 0.5, 1), opacity 2s linear, -webkit-transform 2s cubic-bezier(0, 0.5, 0.5, 1);
    transition-delay: calc(0.1s * var(--block-index));
    opacity: 0;
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
    &:nth-child(n+4) {
      margin-top: 6rem;
    }
    &__wrapper {
      padding: 6rem 2rem;
      width: calc(100% - 60px);
      max-width: 370px;
      margin: 0 auto;
      position: relative;
      position: relative;
      border: 1px solid rgba(255, 255, 255, .2);
      background: rgba(255, 255, 255, .1);
    }
    &__media {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 30px;
      }
    }
    &__title {
      font-family: owners-xwide, sans-serif;
      font-weight: 700;
      font-size: 22px;
      letter-spacing: .05em;
      line-height: 1;
      color: #fff;
    }
    &__media {
      display: none;
    }
    &__excerpt {
      font-family: "Fira Code", monospace;
      color: #fff;
      font-size: 16px;
      margin-top: 20px;
      max-width: 260px;
      margin: 20px auto 0;
      span {
        display: none;
      }
    }
    &__cta {
      position: absolute;
      background: #fff;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      text-decoration: none;
      transition: all .3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      opacity: 0;
      transition: all .3s ease-in-out;
      span {
        color: #181e33;
        font-family: owners-xwide, sans-serif;
        font-weight: 700;
        letter-spacing: .05em;
        display: block;
        width: 100%;
        padding: 10px 20px;
        text-align: center;
      }
    }
    &:hover {
      .sec-two-blocks--item__cta {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 767px) {
  .sec-two-blocks {
    margin: 3rem auto 0;
    flex-wrap: wrap;
    &--item {
      width: 100%;
      margin-top: 0 !important;
      opacity: 1;
      transform: translateY(0);
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      &__wrapper {
        width: 100%;
        padding: 4rem 2rem;
      }
      &__excerpt {
        max-width: 100%;
      }
      &__title {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1170px) {
  .sec-two-blocks {
    flex-wrap: wrap;
    margin: 4rem auto 0;
    &--item {
      width: 100%;
      opacity: 1;
      transform: translateY(0);
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      &:nth-child(n+4) {
        margin-top: 0;
      }
      &__wrapper {
        padding: 5rem 2rem;
        width: 100%;
      }
      &__excerpt {
        max-width: 100%;
      }
      &__title {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: 1171px) and (max-width: 1380px) {
  .sec-two-blocks {
    &--item {
      &__wrapper {
        padding: 6rem 1.5rem;
        width: calc(100% - 40px);
      }
      &__title {
        font-size: 18px;
      }
      &:nth-child(n+4) {
        margin-top: 4rem;
      }
    }
  }
}

@media (min-width: 1381px) {
  .sec-two-blocks {
    margin: 4rem auto 0 -20px;
    &--item {
      &:nth-child(n+4) {
        margin-top: 4rem;
      }
      &__excerpt {
        max-width: 310px;
        span {
          margin-top: 15px;
          display: none;
        }
      }
      &__wrapper {
        padding: 6rem 1.5rem;
        max-width: 400px;
      }
    }
  }
}
</style>
