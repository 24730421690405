import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins/global'
import VueGtag from 'vue-gtag'

const app = createApp({
  extends: App,
  mixins: [mixins]
})

app.use(store)
app.use(router)
app.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG_MEASUREMENT_ID
  }
})
app.mount('#app')
